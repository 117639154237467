<template>
    <div id="content">
        <DataTable :value="data" class="p-datatable-responsive"  selectionMode="single" dataKey="id" :paginator="true" :rows="filters.perPage" :filters="filters"
                   :totalRecords="filters.totalRows" :loading="loading" @page="onPage($event)" :lazy="true"
                   :scrollable="true"
                   scrollHeight="calc(100vh - 310px)"
                   :stickyHeader="true"
                   id="table"
        >
            <template #empty>
                <h6 class="text-center mt-2" v-if="!loading">No se encontraron registros que coincidan con la búsqueda</h6>
                <h6 class="text-center mt-2"  v-else>Buscando</h6>
            </template>
            <template #header>
                <div class="d-flex justify-content-between">
                    <h5 class="text-left">Tipo de consulta</h5>

                    <div style="text-align:left">
                        <Button title="Buscar" icon="pi pi-search" @click="reload()" class="mx-2"/>
                        <Button title="Agregar" icon="pi pi-plus" class="btn-company" @click="onSelectedRow({})" />
                        <Button class="btn btn-company mx-2" @click="clearFilters()" label="Limpiar"/>

                    </div>
                </div>
            </template>
            <Column field="type" header="Consulta" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Consulta</span>
                    {{slotProps.data.title}}
                </template>
                <template #filter>
                    <InputText type="text" v-model="filters.title" class="p-column-filter" placeholder="Buscar por tipo de consulta" @keyup.enter.native="reload()"/>

                </template>
            </Column>
            <Column field="user" header="Correo" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Correo</span>
                    {{slotProps.data.email}}
                </template>
                <template #filter>
                    <InputText type="text" v-model="filters.email" class="p-column-filter" placeholder="Correo" @keyup.enter.native="reload()"/>

                </template>
            </Column>

            <Column field="consultation" header="Estado" :sortable="false"   headerStyle="width: 8em">
                <template #body="slotProps">
                    <span class="p-column-title">Estado</span>
                    {{slotProps.data.is_active ? 'Si':'No'}}
                </template>
                <template #filter>
                    <b-form-select  v-model="filters.is_active" :options="solved"
                                    value-field="id"
                                    text-field="title"
                                    @change="reload"
                    ></b-form-select>
                </template>
            </Column>
            <Column field="id" header="Acciones" :sortable="false" headerStyle="width: 6rem" bodyClass="action">
                <template #body="slotProps" class="tex">
                    <Button abel="Editar" icon="pi pi-pencil" @click="onSelectedRow(slotProps.data)" class="m-auto"/>

                </template>

            </Column>


        </DataTable>
        <Dialog :visible.sync="showForm" :style="{width: '400px'}" :header="titleForm" :modal="true">
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(onSubmit)" ref="form" name="form">
                    <div class="p-cardialog-content">
                        <b-container class="py-4">
                            <b-row class="mb-3">
                                <b-col>
                                    <div class="form-group ">
                                        <ValidationProvider name="Tipo de consulta" :rules="{ required: true}"
                                                            v-slot="validationContext" >
                                            <div class="p-float-label">
                                                <InputText id="title" type="text" v-model="model.title"  class="w-100"/>
                                                <b-form-invalid-feedback    :state="true" :class="{'d-block':true}">{{ validationContext.errors.join(',')}}</b-form-invalid-feedback>
                                                <label for="title">Tipo de consulta</label>

                                            </div>

                                        </ValidationProvider>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row class="mb-3">
                                <b-col>
                                    <div class="form-group mb-3">
                                        <ValidationProvider name="correo" :rules="{ required: true, email:true }"
                                                            v-slot="validationContext" >
                                            <div class="p-float-label">
                                                <InputText id="mail" type="email" v-model="model.email"  class="w-100"/>
                                                <b-form-invalid-feedback    :state="true" :class="{'d-block':true}">{{ validationContext.errors.join(',')}}</b-form-invalid-feedback>
                                                <label for="mail">Correo</label>

                                            </div>

                                        </ValidationProvider>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row class="mb-3">
                                <b-col sm="12">
                                    <label for="is_active">Estado</label>

                                </b-col>
                                <b-col>
                                    <div class="form-group mb-5">
                                        <ValidationProvider name="is_active" :rules="{ required: true}"
                                                            v-slot="validationContext" >
                                            <Dropdown id="is_active" v-model="model.is_active" :options="status" optionLabel="title" optionValue="id" placeholder="Activo" class="w-100" />
                                        </ValidationProvider>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-container>
                    </div>


                </b-form>
            </validation-observer>
            <template #footer>
                <Button :label="!loading ? 'Guardar':'Guardando'" :icon="loading ? 'pi pi-spin pi-spinner' :'pi pi-check'"  class="p-button-success" @click="onSubmit()" :disabled="loading"   />
            </template>
        </Dialog>

    </div>


</template>

<script>

    import Crud from '../../service/Crud';
    import Dialog from 'primevue/dialog'
    import { BFormInvalidFeedback } from 'bootstrap-vue'

    export default {

        components: {
            Dialog,BFormInvalidFeedback
        },
        data() {
            return {
                filters: {
                    page:1,
                    perPage: 20,
                    totalRows:0,
                    is_active:-1
                },
                model:{
                    is_active:false
                },
                loading:false,
                showForm:false,
                data: [],
                layout: 'list',
                sortKey: null,
                sortOrder: null,
                sortField: null,
                crud: null,
                selected:null,
                solved:[
                    {
                        id: -1,
                        title:'Todo'
                    },
                    {
                        id:1,
                        title:'Si'
                    },
                    {
                        id:0,
                        title:'No'
                    }
                ],
                status:[
                    {
                        id:true,
                        title:'Si'
                    },
                    {
                        id:false,
                        title:'No'
                    }
                ]
            }

        },
        created() {
            this.crud = Crud('consultation-types');
            this.crud.findAll(this.filters).then(res =>{
                this.data = res.data ;
                this.filters.totalRows = res.meta.total;
            });
        },
        mounted() {
        },
        methods: {
            clearFilters() {
                const omit  = ['perPage', 'totalRows', 'page'];
                Object.keys(this.filters).map(((value) => { if( omit.indexOf(value) === -1){ this.filters[value] = null} }));
                this.reload();
            },
            initForm(){
                this.model = {
                    is_active:false
                };
            },
            reload(){
                this.loading = true;
                this.crud.findAll(this.filters).then(res =>{
                    this.data = res.data ;
                    this.filters.totalRows = res.meta.total;
                    this.loading = false;

                });
            },
            onPage(event){
                this.filters.page  = event.page + 1;
                this.reload();
            },
            onSubmit(){
                this.$refs.observer.validate();
                if(!this.$refs.observer.flags.invalid){
                   this.sendModelForm(this.model.id);

                }
            },
            onSelectedRow(item){
                this.selected = item;
                this.initForm();
                Object.assign(this.model, item);
                this.showForm = true

            },
            onSuccess(){
                this.$toast.add({severity:'success', summary:"Guardado", life: 3000});
                this.loading = false;
                this.showForm = false;
                this.initForm();
                this.reload();

            },
            onError(response){
                this.$toast.add({severity:'error', summary: response.data.message, life: 3000});
                this.loading = false;
            },
            sendModelForm(id = null){
                this.loading = true;
                const send = Object.assign({}, this.model);
                if(!id) {
                    this.crud.store(send).then( () => {
                        this.onSuccess();
                    },  (response) => {
                        this.onError(response)
                    });
                }else {
                    this.crud.update(id,send).then( () => {
                        this.onSuccess();
                    },  (response) => {
                        this.onError(response)
                    });
                }

            }

        },
        computed:{
            isEdit(){
                return this.selected && this.selected.id;
            },
            titleForm(){ return this.isEdit ? 'Editar': 'Nuevo' }

        }
    }
</script>

<style lang="scss">
    .p-dropdown-panel{
        margin-left: 15px;
    }
    .action{
        display: flex;
        > * {
            margin: auto;
        }

    }
</style>
<style lang="scss" scoped>

</style>
